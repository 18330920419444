import React from "react";
import { ReactComponent as NoResultFound } from "@/app/assets/icons/no-result-found.svg";
import BaseFlex from "../BaseFlex/BaseFlex";
import BaseText from "../BaseText/BaseText";
import { usePrimaryColor } from "@/common/hooks/usePrimaryColor";
import BaseBox from "../BaseBox/BaseBox";

const NotFound: React.FC = () => {
  const color = usePrimaryColor();

  return (
    <BaseFlex flexDirection="column" gap="1rem" align="center">
      <BaseBox fill={color}>
        <NoResultFound width={100} height={100} />
      </BaseBox>
      <BaseText>No result found</BaseText>
    </BaseFlex>
  );
};

export default NotFound;
