import { useColorModeValue } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import BaseCard from "../BaseCard/BaseCard";

const AuthCard: React.FC<PropsWithChildren> = ({ children }) => {
  const cardBG = useColorModeValue(
    "var(--chakra-colors-white)",
    "var(--chakra-colors-gray-700)"
  );

  return (
    <BaseCard background={cardBG} variant="filled">
      {children}
    </BaseCard>
  );
};

export default AuthCard;
