export const SKILLS = [
  "React",
  "Angular",
  "Vue",
  "CSS",
  "SASS",
  "CSS-in-JS",
  "JavaScript",
  "Figma",
  "Photoshop",
  "Webpack",
  "Vite",
];
